import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // user: null,
    user: {},
    userModify: false,
    userAccountModify: false,
    charts: [],
    menu: [],
    selfLogin: false
  },
  getters: {
    user: (state) => {
      return state.user
    },
    userModify: (state) => {
      return state.userModify
    },
    userAccountModify: (state) => {
      return state.userAccountModify
    },
    menu: (state) => {
      return state.menu
    },
    selfLogin: (state) => {
      return state.selfLogin
    }
  },
  mutations: {
  },
  actions: {
    user ({ commit }, data) {
      this.state.user = data
    },
    userModify ({ commit }, data) {
      this.state.userModify = data
    },
    userAccountModify ({ commit }, data) {
      this.state.userAccountModify = data
    },
    menu ({ commit }, data) {
      this.state.menu = data
    },
    selfLogin ({ commit }, data) {
      this.state.selfLogin = data
    }
  },
  modules: {
  }
})
