<template>
  <div class="charts-wrap">
    <div class="charts-content" :style="height ? 'height:' + height + 'px' : 'min-height:400px'" :class="!hasData && 'opacity0'" :id="id"></div>
    <p class="nodata" v-if="!hasData">暂无数据</p>
  </div>
</template>

<script>
import * as Echarts from 'echarts'
import Util from '@/common/util'
export default {
  name: 'EchartsBase',
  props: [
    'data',
    'height'
  ],
  data () {
    return {
      chart: '',
      hasData: false
    }
  },
  computed: {
    id () {
      return 'echarts-' + Util.uuid()
    }
  },
  watch: {
    data (val) {
      this.hasData = Boolean(val && val.series && val.series.length)
      if (this.hasData) {
        this.chart.setOption(val, true)
        this.$store.state.charts[this.id] = this.chart
        this.chart.resize()
      }
    }
  },
  mounted () {
    this.chart = Echarts.init(document.getElementById(this.id))
    setTimeout(() => {
      this.chart.resize()
    })
  }
}
</script>

<style scoped>
.chart-content {
  height: 100%;
  width: 100%;
}
.opacity0 {
  opacity: 0;
}
.charts-wrap {
  background: #fff;
  border-radius: 3px;
  position: relative;
  /*box-shadow: 0 2px 3px hsla(0, 0%, 4%, 0.1), 0 0 0 1px hsla(0, 0%, 4%, 0.1);*/
}
.nodata{
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 12px;
  color: #909399;
  margin-left: -24px;
}
</style>