import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/user/index'
  },
  {
    path: '/user',
    redirect: '/user/index'
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue')
  },
  // 无权限访问
  {
    path: '/permission',
    component: () => import('../views/Permission.vue')
  },
  // 拒绝访问
  {
    path: '/permission2',
    component: () => import('../views/Permission2.vue')
  },
  {
    path: '/user/index',
    component: () => import('../views/user/Index.vue')
  },
  {
    path: '/user/batchuser',
    component: () => import('../views/user/BatchUser.vue')
  },
  {
    path: '/user/batchusermodify',
    component: () => import('../views/user/BatchUserModify.vue')
  },
  {
    path: '/user/batchsearch',
    component: () => import('../views/user/BatchSearch.vue')
  },
  {
    path: '/user/batchaccount',
    component: () => import('../views/user/BatchAccount.vue')
  },
  {
    path: '/user/batchaccountmodify',
    component: () => import('../views/user/BatchAccountModify.vue')
  },
  {
    path: '/user/batchuseraccount',
    component: () => import('../views/user/BatchUserAccount.vue')
  },
  {
    path: '/user/batchuseraccountmodify',
    component: () => import('../views/user/BatchUserAccountModify.vue')
  },
  {
    path: '/access',
    redirect: '/access/index'
  },
  {
    path: '/access/index',
    component: () => import('../views/access/Index.vue')
  },
  {
    path: '/group',
    redirect: '/group/department'
  },
  {
    path: '/group/department',
    component: () => import('../views/group/Department.vue')
  },
  {
    path: '/group/usertype',
    component: () => import('../views/group/Usertype.vue')
  },
  {
    path: '/group/tag',
    component: () => import('../views/group/Tag.vue')
  },
  {
    path: '/group/filters',
    component: () => import('../views/group/Filters.vue')
  },
  {
    path: '/system',
    redirect: '/system/analysis/index'
  },
  {
    path: '/system/analysis',
    redirect: '/system/analysis/index'
  },
  {
    path: '/system/analysis/index',
    component: () => import('../views/system/analysis/Index.vue')
  },
  {
    path: '/system/analysis/face',
    component: () => import('../views/system/analysis/Face.vue')
  },
  {
    path: '/system/analysis/login',
    component: () => import('../views/system/analysis/Login.vue')
  },
  {
    path: '/system/analysis/active',
    component: () => import('../views/system/analysis/Active.vue')
  },
  {
    path: '/system/analysis/ip',
    component: () => import('../views/system/analysis/Ip.vue')
  },
  {
    path: '/system/power',
    redirect: '/system/power/index'
  },
  {
    path: '/system/power/index',
    component: () => import('../views/system/power/Index.vue')
  },
  {
    path: '/system/power/roles',
    component: () => import('../views/system/power/Roles.vue')
  },
  {
    path: '/system/interface',
    redirect: '/system/interface/index'
  },
  {
    path: '/system/interface/index',
    component: () => import('../views/system/interface/Index.vue')
  },
  {
    path: '/system/interface/statistics',
    component: () => import('../views/system/interface/Statistics.vue')
  },
  {
    path: '/system/interface/api',
    component: () => import('../views/system/interface/Api.vue')
  },
  {
    path: '/system/interface/add',
    component: () => import('../views/system/interface/Add.vue')
  },
  {
    path: '/system/configuration',
    redirect: '/system/configuration/index'
  },
  {
    path: '/system/configuration/index',
    component: () => import('../views/system/configuration/Index.vue')
  },
  {
    path: '/system/configuration/syslogsetting',
    component: () => import('../views/system/configuration/Syslog.vue')
  },
  {
    path: '/system/configuration/basic',
    component: () => import('../views/system/configuration/Basic.vue')
  },
  {
    path: '/system/configuration/property',
    component: () => import('../views/system/configuration/Property.vue')
  },
  {
    path: '/system/attribute/index',
    component: () => import('../views/system/attribute/Index.vue')
  },
  {
    path: '/system/configuration/accesspolicy',
    component: () => import('../views/system/configuration/Accesspolicy.vue')
  },
  {
    path: '/system/backup',
    redirect: '/system/backup/index'
  },
  {
    path: '/system/backup/index',
    component: () => import('../views/system/backup/Index.vue')
  },
  {
    path: '/system/backup/external',
    component: () => import('../views/system/backup/External.vue')
  },
  {
    path: '/system/backup/importlog',
    component: () => import('../views/system/backup/ImportLog.vue')
  },
  {
    path: '/system/backup/export',
    component: () => import('../views/system/backup/Export.vue')
  },
  {
    path: '/system/backup/import',
    component: () => import('../views/system/backup/Import.vue')
  },
  {
    path: '/system/backup/externallog',
    component: () => import('../views/system/backup/ExternalLog.vue')
  },
  {
    path: '/system/other',
    redirect: '/system/other/message'
  },
  {
    path: '/system/other/message',
    component: () => import('../views/system/other/Message.vue')
  },
  {
    path: '/system/other/task',
    component: () => import('../views/system/other/Task.vue')
  },
  {
    path: '/system/other/authentication',
    component: () => import('../views/system/other/Authentication.vue')
  },
  {
    path: '/logaudit',
    redirect: '/logaudit/login'
  },
  {
    path: '/logaudit/index',
    component: () => import('../views/logaudit/Index.vue')
  },
  {
    path: '/logaudit/audit',
    component: () => import('../views/logaudit/Audit.vue')
  },
  {
    path: '/logaudit/login',
    component: () => import('../views/logaudit/Login.vue')
  },
  {
    path: '/logaudit/face',
    component: () => import('../views/logaudit/Facerecognition.vue')
  },
  {
    path: '/news',
    redirect: '/news/index'
  },
  {
    path: '/news/index',
    component: () => import('../views/news/Index.vue')
  },
  {
    path: '/decentralization',
    redirect: '/decentralization/index'
  },
  {
    path: '/decentralization/index',
    component: () => import('../views/decentralization/Index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
