import axios from 'axios'
    
import store from '../store'
import Message from 'ant-design-vue/lib/message'
import context from '../main.js'

import Config from '../conf'
import VueCookie from 'vue-cookie'

// axios 配置
// axios.defaults.timeout = 5000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers['Cache-Control'] = 'no-cache'

// POST传参序列化
axios.interceptors.request.use((config) => {
  // // 单个网站的admin用户
  // config.headers.common['Gw-Admin-Access-Token'] = store.getters.adminAccessToken
  // // 整个网点user
  // config.headers.common['Gw-User-Access-Token'] = store.getters.userAccessToken
  return config
}, (err) => {
  Message.error('参数错误')
  return Promise.reject(err)
})

let requestStatus = true

// 返回状态判断
axios.interceptors.response.use((res) => {
  // console.log('this', context.$route.path)
  // console.log('VueCookie', VueCookie)
  // console.log('store.state.selfLogin', store.state.selfLogin)
  const response = res.data
  // alert(JSON.stringify(response))
  if (response.code === 10100) {
    requestStatus = false
    VueCookie.delete('user')
    store.dispatch('user', null)
    // if (context.$route.path === '/permission2') {
    //   context.$router.push('/login')
    //   return false
    // }
    // console.log(res['config']['url'])
    // if (res['config']['url'].indexOf('userinfo') < 0) {
      // alert(context.$route.path !== '/login')
    
    if (context.$route.path !== '/login' && context.$route.path !== '/permission2') {
      if (store.state.selfLogin) {
        requestStatus = false
        context.$router.replace('/login')
        window.location.reload()
        return false
        // let url = window.location.origin + window.location.pathname + '#/login'
        // alert(url)
        // window.location.replace(url)
      } else {
        // window.location.reload()
        // console.log(Config.api + 'login/cas?targetUrl=' + encodeURIComponent(window.location.href))
        // alert(Config.api + 'login/cas?targetUrl=' + encodeURIComponent(window.location.href))
        window.location.href = Config.api + 'login/cas?targetUrl=' + encodeURIComponent(window.location.href)
        return false
      }
    }
    // }
  } else if (response.code === 10000 || response.code === 10200) { //拒绝访问
    requestStatus = false
    VueCookie.delete('user')
    store.dispatch('user', null)
    context.$router.push('/permission2')
    return false
  } else {
    requestStatus = true
  }
  // console.log(res)
  // if (response.msg) {
  //   if (response.code === 0) {
  //     Message.success(response.msg)
  //   } else {
  //     Message.error(response.msg)
  //   }
  // }
  return response
}, (err) => {
  if (err && err.response) {
    switch (err.response.status) {
      case 404:
        Message.error('接口请求异常, 请重试')
        break
      default:
        Message.error('出错啦')
    }
  }
  return Promise.reject(err)
})

export default function request (method, url, data) {
  if (!requestStatus) return false
  // 处理请求的url和数据
  data = method === 'get' ? { params: data } : data
  data = method === 'delete' ? { data: data } : data
  // 发送请求
  return new Promise((resolve, reject) => {
    axios[method](Config.root + url, data)
      .then(response => {
        resolve(response)
      }, error => {
        reject(error)
      })
      .catch(error => {
        reject(error)
      })
  })
  .catch(error => {
    console.log(error)
  })
}