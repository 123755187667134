export default {
  // 这个方法将json转字符串
  serialize: function (json) {
    var arr = []
    for (var k in json) {
      json[k] = json[k] ? ((typeof json[k] === 'object' && json[k].constructor === Array) ? json[k].join(',') : json[k]) : ''
      var str = k + '=' + encodeURIComponent(json[k])
      arr.push(str)
    }
    return arr.join('&')
  },
  // 这个方法将字符串转json
  deserialize: function (str) {
    var arr = str.split('&')
    var data = {}
    for (var i = 0; i < arr.length; i++) {
      var _arr = arr[i].split('=')
      data[_arr[0]] = _arr[1].indexOf(',') >= 0 ? _arr[1].split(',') : _arr[1]
    }
    return data
  },
  // 这个方法flag为true时，将两个json拼接在一起；当flag为false时，返回obj1;
  extends: function (obj1, obj2, flag) {
    var k
    if (flag === true) {
      for (k in obj2) {
        obj1[k] = obj2[k]
      }
    } else {
      for (k in obj1) {
        if (obj2[k]) {
          obj1[k] = obj2[k]
        }
      }
    }
    return obj1
  },
  uuid: function (len = 8) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
    var uuid = []
    var i
    if (len) {
      for (i = 0; i < len; i++) {
        uuid[i] = chars[0 | Math.random() * chars.length]
      }
    } else {
      var r
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
      uuid[14] = '4'
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | Math.random() * 16
          uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
        }
      }
    }
    return uuid.join('')
  },
  numberFormat (num) {
    if (num && num !== '' && num !== undefined && String(num) !== 'undefined') {
      num = num ? num.toString().split('.') : []
      num[0] = num[0] ? num[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
      return num[0]
    } else {
      return num
    }
  },
  getDate (str) {
    var now = new Date(str)
    return now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate()
  },
  isArray (o) {
    return Object.prototype.toString.call(o) === '[object Array]'
  },
  isNumber (val) {
    var re = /^[0-9]+.?[0-9]*/ // 判断字符串是否为数字//判断正整数/[1−9]+[0−9]∗]∗/ 
    if (!re.test(val)) { 
      return false
    }
    return true
  },
  getDataPickerDefault () {
    return [
      (!new Date().getMonth() ? (new Date().getFullYear() - 1) : new Date().getFullYear()) + '-' + (!new Date().getMonth() ? '12' : new Date().getMonth()) + '-' + new Date().getDate(),
      new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()
    ]
  },
  getDateString (date) {
    return date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
  },
  formatSeconds (value) {
    var theTime = parseInt(value)
    var theTime1 = 0
    var theTime2 = 0
    if (theTime > 60) {
      theTime1 = parseInt(theTime / 60)
      theTime = parseInt(theTime % 60)
      if (theTime1 > 60) {
        theTime2 = parseInt(theTime1 / 60)
        theTime1 = parseInt(theTime1 % 60)
      }
    }
    var result = '' + (parseInt(theTime) > 9 ? parseInt(theTime) : '0' + parseInt(theTime))
    if (theTime1 > 0) {
      result = '' + (parseInt(theTime1) > 9 ? parseInt(theTime1) : '0' + parseInt(theTime1)) + ':' + result
    } else {
      result = '' + '00' + ':' + result
    }
    if (theTime2 > 0) {
      result = '' + (parseInt(theTime2) > 9 ? parseInt(theTime2) : '0' + parseInt(theTime2)) + ':' + result
    } else {
      result = '' + '00' + ':' + result
    }
    return result
  },
  isDate (str) {
    return /^(\d{4})-(\d{2})-(\d{2})$/.test(str) || /^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])\s+(20|21|22|23|[0-1]\d):[0-5]\d:[0-5]\d$/.test(str)
  },
  arraySort (arr, field, order = 'descending') {
    if (!this.isArray(arr)) {
      throw new Error('第一个参数必须是数组类型')
    } else {
      var transformFun = this.isDate(arr[0][field]) ? Date.parse : parseFloat
      if (!this.isDate(arr[0][field])) {
        arr.map(item => {
          item[field] = transformFun(item[field])
        })
      }
      arr.sort((a, b) => {
        return (order === 'descending') ? (transformFun(b[field]) - transformFun(a[field])) : (transformFun(a[field]) - transformFun(b[field]))
      })
    }
  },
  timeStampFormat (timeStamp, format) {
    var d = new Date(timeStamp)
    var date = {
      'M+': d.getMonth() + 1,
      'd+': d.getDate(),
      'h+': d.getHours(),
      'm+': d.getMinutes(),
      's+': d.getSeconds(),
      'q+': Math.floor((d.getMonth() + 3) / 3),
      'S+': d.getMilliseconds()
    }
    if (/(y+)/i.test(format)) {
      format = format.replace(RegExp.$1, (d.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var i in date) {
      if (new RegExp('(' + i + ')').test(format)) {
        format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? date[i] : ('00' + date[i]).substr(('' + date[i]).length))
      }
    }
    return format
  }
}
