import Http from '@/common/http'
export default class News {
  static getList (param = {}) {
    return Http('get', 'api/publish/load', param)
  }
  static getCount (param = {}) {
    return Http('get', 'api/publish/count', param)
  }
  static saveNews (param = {}) {
    return Http('post', 'api/publish/detail', param)
  }
  static deleteNews (id, param = {}) {
    return Http('delete', 'api/publish/detail/' + id, param)
  }
  static getNewsDetail (id, param = {}) {
    return Http('get', 'api/publish/detail/' + id, param)
  }
  static changeNews (id, param = {}) {
    return Http('put', 'api/publish/detail/' + id, param)
  }
}
