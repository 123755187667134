import Http from '@/common/http'
export default class Access {
  static getAccessList (param = {}) {
    return Http('get', 'api/service/load', param)
  }
  static getAccessCount (param = {}) {
    return Http('get', 'api/service/count', param)
  }
  static getAppDetail (id, param = {}) {
    return Http('get', 'api/service/detail/' + id, param)
  }
  static getCode (type) {
    return Http('get', 'api/service/code/' + type)
  }
  static getAppSecret (param = {}) {
    return Http('get', 'api/service/app/aecret', param)
  }
  static getAppGenerate (type, param = {}) {
    return Http('get', 'api/service/app/generate/' + type, param)
  }
  static saveAccess (param = {}) {
    return Http('post', 'api/service/detail', param)
  }
  static changeAccess (id, param = {}) {
    return Http('put', 'api/service/detail/' + id, param)
  }
  static deleteAccess (id, param = {}) {
    return Http('delete', 'api/service/detail/' + id, param)
  }
  static deleteLdapAccess (id, param = {}) {
    return Http('delete', 'api/service/ldap/detail/' + id, param)
  }
  static getGeneratePwd (param = {}) {
    return Http('get', 'api/service/ldap/detail/generate/pwd', param)
  }
  static getGenerateDn (param = {}) {
    return Http('get', 'api/service/ldap/detail/generate/dn', param)
  }
  static deleteLdap (id, param = {}) {
    return Http('delete', 'api/service/ldap/delete/' + id, param)
  }
  static changeLdap (id, param = {}) {
    return Http('put', 'api/service/ldap/detail/' + id, param)
  }
  static getLdapDetail (id, param = {}) {
    return Http('get', 'api/service/ldap/detail/' + id, param)
  }
  static getDnDomain (param = {}) {
    return Http('get', 'api/service/ldap/detail/dn/domain', param)
  }
  static addLdap (param = {}) {
    return Http('post', 'api/service/ldap/detail', param)
  }
}
