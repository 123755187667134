<template>
  <div class="batch-menu"  style="padding-top: 0;">
    <ul v-if="menu['system']">
      <li v-if="menu['system'].indexOf('/system/analysis/index') > -1 || menu['system'].indexOf('/system/analysis/face') > -1 || menu['system'].indexOf('/system/analysis/login') > -1 || menu['system'].indexOf('/system/analysis/active') > -1 || menu['system'].indexOf('/system/analysis/ip') > -1" :class="menu['system'] && path.indexOf('/system/analysis') > -1 && 'cur'" @click="changeMenu(1)">统计分析</li>
      <li v-if="menu['system'].indexOf('/system/power/index') > -1 || menu['system'].indexOf('/system/power/roles') > -1" :class="menu['system'] && path.indexOf('/system/power') > -1 && 'cur'" @click="changeMenu(2)">权限管理</li>
      <li v-if="menu['system'].indexOf('/system/interface/index') > -1 || menu['system'].indexOf('/system/interface/statistics') > -1 || menu['system'].indexOf('/system/interface/api') > -1" :class="menu['system'] && path.indexOf('/system/interface') > -1 && 'cur'" @click="changeMenu(3)">接口管理</li>
      <li v-if="menu['system'].indexOf('/system/configuration/index') > -1 || menu['system'].indexOf('/system/configuration/index') > -1" :class="menu['system'] && path.indexOf('/system/configuration') > -1 && 'cur'" @click="changeMenu(4)">系统配置</li>
      <li v-if="menu['system'].indexOf('/system/attribute/index') > -1 || menu['system'].indexOf('/system/attribute/index') > -1" :class="menu['system'] && path.indexOf('/system/attribute') > -1 && 'cur'" @click="changeMenu(7)">数据定义</li>
      <li v-if="menu['system'].indexOf('/system/backup/index') > -1 || menu['system'].indexOf('/system/backup/external') > -1" :class="menu['system'] && path.indexOf('/system/backup') > -1 && 'cur'" @click="changeMenu(5)">数据备份</li>
      <li v-if="menu['system'].indexOf('/system/other/message') > -1 || menu['system'].indexOf('/system/other/task') > -1" :class="menu['system'] && path.indexOf('/system/other') > -1 && 'cur'" @click="changeMenu(6)">其它设置</li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SystemMenu',
  data () {
    return {
      path: 'index'
    }
  },
  watch: {
    '$route': function (val) {
      this.path = val.path
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'menu'
    ])
  },
  methods: {
    checkArrayHas (val, arr) {
      if (!arr.length) return false
      let status = false
      for (let key in arr) {
        for (let k in arr[key]) {
          if (arr[key][k] === val) {
            status = true
            break
          }
        }
      }
      return status
    },
    checkArrayHasTag (arr) {
      if (!arr.length) return false
      let status = false
      for (let key in arr) {
        if (arr[key].length === 1) {
          status = true
          break
        }
      }
      return status
    },
    changeMenu (index) {
      if (index === 1) {
        this.$router.push('/system/analysis/index')
      }
      if (index === 2) {
        this.$router.push('/system/power/index')
      }
      if (index === 3) {
        this.$router.push('/system/interface/index')
      }
      if (index === 4) {
        this.$router.push('/system/configuration/index')
      }
      if (index === 5) {
        this.$router.push('/system/backup/index')
      }
      if (index === 6) {
        this.$router.push('/system/other/message')
      }
      if (index === 7) {
        this.$router.push('/system/attribute/index')
      }
    },
  },
  created () {
    this.path = this.$route.path
  }
};
</script>

<style scoped>
.batch-menu {
  width: 100%;
}
.batch-menu ul {
  margin-top: 10px;
}
.batch-menu ul li {
  line-height: 40px;
  cursor: pointer;
  font-size: 14px;
  color: rgba(0,0,0,0.65);
  text-indent: 40px;
  margin-bottom: 10px;
}
.batch-menu ul li.cur {
  color: #226FBB;
  font-weight: bold;
  background: #E6F7FF;
  border-right: 4px solid #226FBB;
}
</style>
