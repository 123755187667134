import Http from '@/common/http'
export default class System {
  static getGeneralSummary (param = {}) {
    return Http('get', 'api/statistic/general/summary', param)
  }
  static getGeneralSummaryRegistered (param = {}) {
    return Http('get', 'api/statistic/general/summary/registered', param)
  }
  static getFacerecognitionSummary (param = {}) {
    return Http('get', 'api/statistic/facerecognition/summary', param)
  }
  static getFacerecognitionSummaryAuthentication (param = {}) {
    return Http('get', 'api/statistic/facerecognition/summary/authentication', param)
  }
  static getLoginSummary (param = {}) {
    return Http('get', 'api/statistic/login/summary', param)
  }
  static getLoginSummaryFailed (param = {}) {
    return Http('get', 'api/statistic/login/summary/failed', param)
  }
  static getServiceaccessSummary (param = {}) {
    return Http('get', 'api/statistic/serviceaccess/summary', param)
  }
  static getServiceaccessSummaryTimes (param = {}) {
    return Http('get', 'api/statistic/serviceaccess/summary/times', param)
  }
  static getIpaccessSummary (param = {}) {
    return Http('get', 'api/statistic/ipaccess/summary', param)
  }
  static getIpaccessSummaryFailed (param = {}) {
    return Http('get', 'api/statistic/ipaccess/summary/failed', param)
  }
  static getAuthorizationUser (param = {}) {
    return Http('get', 'api/authorization/user', param)
  }
  static deleteAuthorizationUser (id, param = {}) {
    return Http('delete', 'api/authorization/user/' + id, param)
  }
  static getAuthorizationActiveRole (param = {}) {
    return Http('get', 'api/authorization/user/active/role', param)
  }
  static getAuthorizationCode (type, param = {}) {
    return Http('get', 'api/authorization/code/' + type, param)
  }
  static changeAuthorizationUser (id, param = {}) {
    return Http('post', 'api/authorization/user/' + id, param)
  }
  static getAuthorizationRole (param = {}) {
    return Http('get', 'api/authorization/role', param)
  }
  static addAuthorizationRole (param = {}) {
    return Http('post', 'api/authorization/role/detail', param)
  }
  static changeAuthorizationRole (id, param = {}) {
    return Http('put', 'api/authorization/role/detail/' + id, param)
  }
  static deleteAuthorizationRole (id, param = {}) {
    return Http('delete', 'api/authorization/role/detail/' + id, param)
  }
  static getRoleUserList (id, param = {}) {
    return Http('get', 'api/authorization/role/detail/' + id + '/user', param)
  }
  static getApp (param = {}) {
    return Http('get', 'api/app', param)
  }
  static getAppGenerate (type, param = {}) {
    return Http('get', 'api/app/generate/' + type, param)
  }
  static addApp (param = {}) {
    return Http('post', 'api/app/detail', param)
  }
  static getAppDetail (id, param = {}) {
    return Http('get', 'api/app/detail/' + id, param)
  }
  static getAppStatistic (id, param = {}) {
    return Http('get', 'api/app/statistic/api/top/' + id, param)
  }
  static getAppStatisticList (id, param = {}) {
    return Http('get', 'api/app/statistic/api/list/' + id, param)
  }
  static getAppStatisticListDetail (id, param = {}) {
    return Http('get', 'api/app/statistic/app/list/' + id, param)
  }
  static getAllAppStatistic (param = {}) {
    return Http('get', 'api/app/statistic/top', param)
  }
  static getAllAppStatisticList (param = {}) {
    return Http('get', 'api/app/statistic/list', param)
  }
  static getAllApiStatistic (param = {}) {
    return Http('get', 'api/app/statistic/api/top', param)
  }
  static getAllApiStatisticList (param = {}) {
    return Http('get', 'api/app/statistic/api/list', param)
  }
  static changeApp (id, param = {}) {
    return Http('put', 'api/app/detail/' + id, param)
  }
  static deleteApp (id, param = {}) {
    return Http('delete', 'api/app/detail/' + id, param)
  }
  static getCode (type, param = {}) {
    return Http('get', 'api/app/code/' + type, param)
  }
  static getPasswordverify (param = {}) {
    return Http('get', 'api/system/securitypolicy/passwordverify', param)
  }
  static putPasswordverify (param = {}) {
    return Http('put', 'api/system/securitypolicy/passwordverify', param)
  }
  static getAccountsafety (param = {}) {
    return Http('get', 'api/system/securitypolicy/accountsafety', param)
  }
  static putAccountsafety (param = {}) {
    return Http('put', 'api/system/securitypolicy/accountsafety', param)
  }
  static getPasswordweak (param = {}) {
    return Http('get', 'api/system/securitypolicy/passwordweak', param)
  }
  static putPasswordweak (param = {}) {
    return Http('put', 'api/system/securitypolicy/passwordweak', param)
  }
  static putPasswordweakRest (param = {}) {
    return Http('put', 'api/system/securitypolicy/passwordweak/reset', param)
  }
  static getAcrossregion (param = {}) {
    return Http('get', 'api/system/securitypolicy/acrossregion', param)
  }
  static putAcrossregion (param = {}) {
    return Http('put', 'api/system/securitypolicy/acrossregion', param)
  }
  static getBasicsetting (param = {}) {
    return Http('get', 'api/system/basicsetting', param)
  }
  static putBasicsetting (param = {}) {
    return Http('put', 'api/system/basicsetting', param)
  }
  static getAccesspolicy (param = {}) {
    return Http('get', 'api/system/accesspolicy', param)
  }
  static putAccesspolicy (param = {}) {
    return Http('put', 'api/system/accesspolicy', param)
  }
  static getSyslogsetting (param = {}) {
    return Http('get', 'api/system/syslogsetting', param)
  }
  static putSyslogsetting (param = {}) {
    return Http('put', 'api/system/syslogsetting', param)
  }
  static getSystemProperty (param = {}) {
    return Http('get', 'api/system/property', param)
  }
  static postSystemProperty (param = {}) {
    return Http('post', 'api/system/property', param)
  }
  static putSystemProperty (param = {}) {
    return Http('put', 'api/system/property', param)
  }
  static deleteSystemProperty (param = {}) {
    return Http('delete', 'api/system/property', param)
  }
  static getNotification (param = {}) {
    return Http('get', 'api/notification', param)
  }
  static putNotification (name, status, param = {}) {
    return Http('put', 'api/notification/modify/' + name + '/' + status, param)
  }
  static getNotificationList (param = {}) {
    return Http('get', 'api/notification/service', param)
  }
  static deleteService (id, param = {}) {
    return Http('delete', 'api/notification/service/detail/' + id, param)
  }
  static getServiceCode (type, param = {}) {
    return Http('get', 'api/notification/code/' + type, param)
  }
  static addService (param = {}) {
    return Http('post', 'api/notification/service/detail', param)
  }
  static changeService (param = {}) {
    return Http('put', 'api/notification/service/detail', param)
  }
  static getTask (param = {}) {
    return Http('get', 'api/task', param)
  }
  static addTask (param = {}) {
    return Http('post', 'api/task/detail', param)
  }
  static getTaskDetail (id, param = {}) {
    return Http('get', 'api/task/detail/' + id, param)
  }
  static executeTask (id, param = {}) {
    return Http('post', 'api/task/execute/' + id, param)
  }
  static deleteTask (id, param = {}) {
    return Http('delete', 'api/task/detail/' + id, param)
  }
  static changeTaskStatus (type, id, param = {}) {
    return Http('put', 'api/task/detail/' + type + '/' + id, param)
  }
  static changeTask (id, param = {}) {
    return Http('put', 'api/task/detail/' + id, param)
  }
  static getTaskDetailLogging (id, param = {}) {
    return Http('get', 'api/task/detail/logging/' + id, param)
  }
  static getTaskCode (id, param = {}) {
    return Http('get', 'api/task/code/' + id, param)
  }
  static getCodesList (param = {}) {
    return Http('get', 'api/backup/export/options', param)
  }
  static backupExport (param = {}) {
    return Http('post', 'api/backup/export', param)
  }
  static getBackupList (param = {}) {
    return Http('get', 'api/backup/export/record', param)
  }
  static deleteExport (id, param = {}) {
    return Http('delete', 'api/backup/export/record/detail/' + id, param)
  }
  static getImportList (param = {}) {
    return Http('get', 'api/backup/import/record', param)
  }
  static getImportUserList (type, id, param = {}) {
    return Http('get', 'api/backup/import/record/detail/user/dataload/' + type + '/' + id, param)
  }
  static getExternalUserList (type, id, param = {}) {
    return Http('get', 'api/backup/import/external/dataload/' + type + '/' + id, param)
  }
  static externalImport (id, param = {}) {
    return Http('post', 'api/backup/import/external/' + id, param)
  }
  static externalImportStatus (id, param = {}) {
    return Http('get', 'api/backup/import/external/status/' + id, param)
  }

  static getAuthorizationUserRoles (id, param = {}) {
    return Http('get', 'api/authorization/user/' + id, param)
  }
  static deleteAuthorizationUserRoles (id, role_id, param = {}) {
    return Http('delete', 'api/authorization/user/' + id + '/' + role_id, param)
  }
  static changeAuthorizationUserRoles (id, role_id, action, param = {}) {
    return Http('put', 'api/authorization/user/' + action + '/' + id + '/' + role_id, param)
  }

  static getDecentralizationUser (param = {}) {
    return Http('get', 'api/extend/decentralization/user', param)
  }
  static addDecentralizationUser (id, param = {}) {
    return Http('post', 'api/extend/decentralization/user/' + id, param)
  }
  static changeDecentralizationUser (id, param = {}) {
    return Http('put', 'api/extend/decentralization/user/' + id, param)
  }
  static deleteDecentralizationUser (id, param = {}) {
    return Http('delete', 'api/extend/decentralization/user/' + id, param)
  }
  static getDecentralizationUserRole (param = {}) {
    return Http('get', 'api/extend/decentralization/user/active/role', param)
  }

  static getDecentralizationDepartment (param = {}) {
    return Http('get', 'api/extend/decentralization/code/department', param)
  }

  static getAttributeQuery (param = {}) {
    return Http('get', 'api/attribute/extend/query', param)
  }
  static getAttributeCount (param = {}) {
    return Http('get', 'api/attribute/extend/count', param)
  }
  static getAttributeCode (type, param = {}) {
    return Http('get', 'api/attribute/extend/code/' + type, param)
  }
  static postAttribute (param = {}) {
    return Http('post', 'api/attribute/extend/detail', param)
  }
  static putAttribute (type, param = {}) {
    return Http('put', 'api/attribute/extend/detail/' + type, param)
  }
  static deleteAttribute (type, param = {}) {
    return Http('delete', 'api/attribute/extend/detail/' + type, param)
  }
  static checkAttributeDetail (type, param = {}) {
    return Http('get', 'api/attribute/extend/detail/' + type + '/used', param)
  }
  static getAuthenticationCasQuery (param = {}) {
    return Http('get', 'api/authentication/cas/query', param)
  }
  static getAuthenticationCasCount (param = {}) {
    return Http('get', 'api/authentication/cas/count', param)
  }
  static deleteAuthenticationCasRemove (param = {}) {
    return Http('delete', 'api/authentication/cas/remove', param)
  }
  static getAuthenticationCode (type, param = {}) {
    return Http('get', 'api/authentication/code/' + type, param)
  }
}
