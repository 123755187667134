<template>
  <div class="search-bar-warp flex-box" :spinning="loading">
    <a-form-model :model="form" @keyup.native.enter="onSubmit" label-width="120px">
      <div v-for="(item, index) in keys" :key="index" class="form-item-div" v-show="!showMore ? index < 4 : true">
        <a-form-model-item v-if="item.type === 'input'">
          <a-input v-model="form[item.key]" :placeholder="item.name" :size="size" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="item.type === 'labelSelect' && (index < 4 || showMore)">
          <!-- <template slot="label"> -->
          <a-select v-model="form[item.keys[0]]" placeholder="证件类型" :size="size" allowClear @change="form[item.keys[1]] = ''">
            <a-select-option :value="labelSelectItem.id" v-for="(labelSelectItem, labelSelectIndex) in filters[item['useData'][0]]" :key="labelSelectIndex" :size="size">{{labelSelectItem.name}}</a-select-option>
          </a-select>
          <!-- </template> -->
          <!-- <a-input v-model="form[item.keys[1]]" placeholder="请输入证件号"></a-input> -->
        </a-form-model-item>
        <a-form-model-item v-if="item.type === 'labelSelect' && (index < 4 || showMore)">
          <a-input v-model="form[item.keys[1]]" placeholder="请输入证件号" :size="size" allowClear :disabled="!form[item.keys[0]]"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="item.type === 'selectTreeSingle' && (index < 4 || showMore)">
          <!-- <a-cascader :options="filters[item['useData']]" :placeholder="item.name" :field-names="{ checkStrictly: true, label: 'name', value: 'id', children: 'children' }" v-model="form[item.key]" :display-render="displayRender" :size="size" allowClear></a-cascader> -->
          <el-cascader v-model="form[item.key]" :show-all-levels="false" :placeholder="item.name" :props="{ checkStrictly: true, label: 'name', value: 'id', children: 'children', emitPath: false }" filterable clearable :options="filters[item['useData']]" size="small" class="custom-el-cascader"></el-cascader>
        </a-form-model-item>
        <a-form-model-item v-if="item.type === 'select' && (index < 4 || showMore)">
          <a-select v-model="form[item.key]" :placeholder="item.name" :size="size" allowClear>
            <a-select-option :value="selectItem.id" v-for="(selectItem, selectIndex) in filters[item['useData']]" :key="selectIndex">{{selectItem.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-item :label="item.name + '：' || ''" v-if="item.type === 'selectDisable'">
          <a-select v-model="form[item.key]" placeholder="请选择" style="width:210px;">
            <a-option label="启用" value="0"></a-option>
            <a-option label="停用" value="1"></a-option>
          </a-select>
        </a-form-item> -->
        <a-form-model-item v-if="item.type === 'datePicker' && (index < 4 || showMore)">
          <a-date-picker v-model="form[item.key]" value-format="yyyy年MM月dd日" align="right" type="date" placeholder="选择日期" :size="size" allowClear></a-date-picker>
        </a-form-model-item>
        <a-form-model-item v-if="item.type === 'dateRange' && (index < 4 || showMore)">
          <!-- <a-date-picker v-model="form[item.key]" value-format="yyyy年MM月dd日" align="right" type="date" placeholder="选择日期" style="width:193px;"></a-date-picker> -->
          <a-date-picker v-model="daterange[item.key]['value']" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :size="size" allowClear></a-date-picker>
        </a-form-model-item>
        <!-- {{ form }}

        {{ fileInfo }} -->
        <a-form-model-item v-if="item.type === 'file' && (index < 4 || showMore) && form['batchid']">
          <div class="filename">
            {{ fileInfo['name'] }}
          </div>
          <div class="filebtn">
            <a-button size="small" type="link" @click.stop="deleteFile">清除文件</a-button>
          </div>
        </a-form-model-item>
        <a-form-model-item v-if="item.type === 'file' && (index < 4 || showMore) && !form['batchid']">
          <div class="file-box auto">
            <a-upload-dragger
              :showUploadList="false"
              name="file1"
              :action="config.api + 'user/upload'"
              @change="handleChange"
            >
              <p class="ant-upload-drag-icon excel-icon">
                <a-icon type="file-excel" style="color: #ccc;" />
              </p>
              <p class="ant-upload-text excel-text">
                将文件拖拽至此区域
              </p>
              <p class="ant-upload-hint excel-btn">
                <a-button size="small">上传文件</a-button>
              </p>
              <p class="ant-upload-hint excel-btn excel-btn-link">
                <!-- <a :href="config.api + 'user/template'" target="_blank" download @click.stop>下载模板</a> -->
                <a-button size="small" type="link" @click.stop="downloadTemplate">下载模板</a-button>
              </p>
            </a-upload-dragger>
          </div>
        </a-form-model-item>
      </div>
      <div class="more-btn auto">
        <span v-if="!showMore" @click="showMore = true">更多条件<a-icon type="down" /></span>
        <span v-else @click="showMore = false">收起<a-icon type="up" /></span>
      </div>
      <div class="form-item-div btnR">
        <a-form-model-item>
          <a href="javascript:;" @click="resetForm('refForm')">重置</a>
          <a-button type="primary" @click="onSubmit" :size="size">查询</a-button>
        </a-form-model-item>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { Filter } from '@/model'
export default {
  name: 'SearchBar',
  props: [
    'keys'
  ],
  data () {
    return {
      loading: false,
      size: 'small',
      filters: {},
      form: {},
      daterange: {},
      uploadLoading: false,
      statusInterval: null,
      showMore: false,
      fileInfo: {}
    }
  },
  watch: {
    'keys': function (val) {
      this.initForm(val)
      this.initFilter(val)
    },
    'daterange': {
      handler: function (val) {
        for (let key in val) {
          let item = val[key]
          if (item['value']) {
            this.form[item['keys'][0]] = this.turnDate(item['value'][0])
            this.form[item['keys'][1]] = this.turnDate(item['value'][1])
          } else {
            this.form[item['keys'][0]] = undefined
            this.form[item['keys'][1]] = undefined
          }
        }
      },
      deep: true
    },
    'form': {
      handler: function (val) {
        let form = JSON.parse(JSON.stringify(this.form))
        for (let key in this.form) {
          if (this.form[key]) {
            form[key] = this.form[key]
          } else {
            form[key] = ''
          }
        }
        this.$emit('getSearchCache', form)
      },
      deep: true
    }
  },
  methods: {
    downloadTemplate () {
      let url = this.config.api + 'user/template'
      window.open(url, '_blank')
    },
    deleteFile () {
      this.form['batchid'] = ''
      this.fileInfo = {}
    },
    handleChange(info) {
      this.uploadLoading = true
      const status = info.file.status;
      if (status === 'done') {
        // console.log(info)
        if (!info.file.response['code']) {
          // this.uploadId = info.file.response.data
          this.fileInfo = info['file']
          this.form['batchid'] = info.file.response.data
          this.form = JSON.parse(JSON.stringify(this.form))
          this.statusInterval = setInterval(() => {
            // this.getBatchUserMesStatus(this.uploadId)
          }, 1000)
        } else {
          // this.$message.error(info.file.response['description'])
          this.$message.error(this.errMsg[info.file.response['code']])
        }
      } else if (status === 'error') {
        this.uploadLoading = false
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    turnDate (date) {
      let time = new Date(date)
      var y = time.getFullYear()
      var m = time.getMonth() + 1
      var d = time.getDate()
      return y + '年' + m + '月' + d + '日'
    },
    openFn () {
      this.length = 100
    },
    closeFn () {
      this.length = 2
    },
    resetForm () {
      this.initForm(this.keys)
    },
    onSubmit() {
      let form = JSON.parse(JSON.stringify(this.form))
      for (let key in this.form) {
        if (this.form[key]) {
          form[key] = this.form[key]
          // if (key === 'department' && this.form['department']) {
          //   form['department'] = this.form['department'][this.form['department'].length - 1]
          // }
          // if (key === 'type' && this.form['type']) {
          //   form['type'] = this.form['type'][this.form['type'].length - 1]
          // }
        } else {
          form[key] = ''
        }
      }
      // console.log('this.form', form)
      this.$emit('getSearch', form)
    },
    initForm (keys) {
      if (keys && keys.length) {
        let form = {}
        let daterange = {}
        keys.map(item => {
          if (item.type === 'labelSelect') {
            form[item['keys'][0]] = undefined
            form[item['keys'][1]] = undefined
          } else {
            if (item.type === 'dateRange') {
              daterange[item['key']] = {
                keys: [item['keys'][0], item['keys'][1]],
                value: ''
              }
              form[item['keys'][0]] = undefined
              form[item['keys'][1]] = undefined
            } else {
              form[item['key']] = undefined
            }
          }
        })
        this.form = form
        this.daterange = daterange
      }
    },
    initFilter (keys) {
      if (keys && keys.length) {
        this.filters = {}
        this.getCode()
      }
    },
    async getCode () {
      this.loading = true
      let res1 = await Filter.getCode('department')
      this.filters['department'] = res1['data']
      let res2 = await Filter.getCode('usertype')
      this.filters['usertype'] = res2['data']
      let res3 = await Filter.getCode('role')
      this.filters['roles'] = res3['data']
      let res5 = await Filter.getCode('tag')
      let tags = []
      res5['data'].map(item => {
        if (item.type !== 'dynamic_runtime') {
          tags.push(item)
        }
      })
      this.filters['tag'] = tags
      let res4 = await Filter.getCode('basic')
      this.filters['idCardType'] = res4['data']['id']
      this.filters['atSchoolStatus'] = res4['data']['atSchoolStatus']
      // this.filters['isRegistered'] = res4['data']['isRegistered']
      this.filters['isRegistered'] = [{"id":"Y","name":"已注册"},{"id":"N","name":"未注册"}]
      // this.filters['isLocked'] = res4['data']['isLocked']
      this.filters['isLocked'] = [{"id":"unlocked","name":"未锁定"},{"id":"admin_locked","name":"管理员锁定"},{"id":"risks_locked","name":"风险锁定"},{"id":"unuse_locked","name":"闲置锁定"}]
      this.filters['gender'] = res4['data']['gender']
      this.filters['political'] = res4['data']['political']
      this.filters['operateType'] = res4['data']['operateType']
      this.filters['enabledStatus'] = [{"id":"permit","name":"启用"},{"id":"prohibit","name":"停用"},{"id":"limittime","name":"定期停用"}]
      this.filters['isEmpty'] = [{"id":"Y","name":"为空"},{"id":"N","name":"非空"}]
      this.filters = Object.assign({}, this.filters)
      this.loading = false
    }
  },
  created () {
    this.initForm(this.keys)
    this.initFilter(this.keys)
  }
}
</script>

<style scoped>
#app .ant-form-item {
  margin-bottom: 0;
}
.search-bar-warp {
  padding: 0 20px;
  overflow: auto;
}
.search-bar-warp form {
  width: 100%;
}
.search-bar-warp form .form-item-div {
  /*width: 33%;
  margin-right: 0;
  display: inline-block;*/
}

.open {
  font-size: 12px;
}

.open {
  margin-left: 10px;
}

.search-bar-warp form .btnR {
  float: right;
}
.search-bar-warp form .btnR a {
  margin-right: 10px;
  text-decoration: underline;
}

.file-box {
  margin-top: 4px;
}
.excel-text {
  font-size: 12px!important;
  color: rgba(0,0,0,0.25)!important;
}
.excel-btn-link {
  margin-top: 10px;
}
.more-btn {
  color: rgba(0,0,0,0.65);
  padding: 10px 0;
}
.more-btn span {
  cursor: pointer;
}
.more-btn span i {
  margin-left: 10px;
}

.filename {
  padding-top: 10px;
}
.filebtn {
  text-align: right;
}
</style>
